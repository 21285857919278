html, body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevents scrollbars */
}

#root {
  height: 100%; /* Ensures the root div takes up the full height */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width:100vw;
  max-height:100vh;
}

body:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Nirvana';
  src:  url('./assets/fonts/NIRVANA.ttf') format('truetype');

}
